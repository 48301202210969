import useI18nText from 'hooks/useI18n'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const HomeBody = styled.div`
  div {
    float: left;
    margin-top: -2px;
  }

  .div1 {
    width: 100%;
    p {
      position: 'absolute';
      padding: '1.5rem 3rem';
      color: 'white';
      font-weight: 600;
      font-size: '2rem';
    }
  }
`

const Home = () => {
  const t = useI18nText()

  return (
    <HomeBody>
      <div className="div1" style={{ position: 'relative' }}>
        <p style={{ position: 'absolute', padding: '1.5rem 3rem', color: 'white', fontWeight: 600, fontSize: '10vw' }}>
          COLAPAD
          <br />
          <span
            style={{ fontSize: '4vw', textShadow: '4px 4px 5px rgba(241, 243,  51, 0.5)', background: '#000000aa' }}
          >
            {t('Fairer and more transparent BSC rune launch pad')}
          </span>
        </p>

        {/* <p style={{ position: 'absolute', padding: '1.5rem 3rem',  color: 'white', fontWeight: 600, fontSize: '2rem' }}>
          <span style={{ fontSize: '14px' }}>{t('Fairer and more transparent BSC rune launch pad')}</span>
        </p> */}
        <img src="./imgs/p1.png" alt="" />
      </div>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '80%',
            borderRadius: '10px',
            background: '#23a094',
            color: 'white',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          <p style={{ fontSize: '2.5vw', marginTop: '4vw', padding: '0px 5px' }}>
            {t('Create unlimited airdrop opportunities for CoLa ecological participants!')}
          </p>
          <div style={{ position: 'absolute', left: '0px', bottom: '0px' }}>
            <img style={{ width: '40%', float: 'left' }} src="./imgs/p22.png" alt="" />
            <p
              style={{
                fontSize: '2.5vw',
                width: '60%',
                float: 'right',
                boxSizing: 'border-box',
                marginTop: '4vw',
                padding: '1.5vw 2vw',
              }}
            >
              {t(
                'CoLaPad is a launchpad based on Rune Protocol, Create the next liquidity through rune taxation, Use the credentials obtained from mining ($CoLaWL), Free Mint Cola Launchpad for all runes.'
              )}
            </p>
            {/* <p
              style={{
                width: '60%',
                height: '50%',
                float: 'right',
                fontSize: '5vw',
                padding: '5px',
                lineHeight: '16px',
              }}
            >
              {t(
                'CoLaPad is a launchpad based on Rune Protocol, Create the next liquidity through rune taxation, Use the credentials obtained from mining ($CoLaWL), Free Mint Cola Launchpad for all runes.'
              )}
            </p> */}
          </div>
        </div>
        <img src="./imgs/p2.png" alt="" />
      </div>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: '15%',
            left: '53%',
            // transform: 'translate(-50%, 23%)',
            color: '#000',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          <p style={{ fontSize: '2.5vw', marginTop: '3vw', textAlign: 'left', lineHeight: '16px' }}>{t('Economics')}</p>
        </div>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, 60%)',
            width: '50%',
            color: 'white',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          <p style={{ fontSize: '2.5vw', marginTop: '10px', textAlign: 'left' }}>
            {t('Total runes')}：2100万（100%） <br /> {t('Fair Mint')}：12600000（60%）
            <br /> {t('Liquidity')}：8400000（40%） <br />
            <span style={{ fontSize: '1.8vw' }}>0xDFEDE13934A7E447797c500dDD604D0f82205d35</span>
          </p>
        </div>
        <img src="./imgs/p3.png" alt="" />
      </div>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '80%',
            borderRadius: '10px',
            color: 'white',
            fontWeight: 600,
            textAlign: 'center',
          }}
        >
          <img src="./imgs/p44.png" alt="" />
        </div>
        <img src="./imgs/p4.png" alt="" />
      </div>
    </HomeBody>
  )
}

export default Home
