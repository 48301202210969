import styled, { DefaultTheme } from 'styled-components'
import { Variant, variants } from '../Button/types'

type StyledButtonMenuProps = {
  variant: Variant
  theme: DefaultTheme
}

const getBackgroundColor = ({ theme, variant }: StyledButtonMenuProps) => {
  return theme.colors[variant === variants.SUBTLE ? 'input' : 'tertiary']
}

const StyledButtonMenu = styled.div<{ variant: Variant; index }>`
  color: #000;
  background-color: #fff;
  border-radius: 20px;
  display: inline-flex;
  height: 40px;
  line-height: 40px;

  a {
    line-height: 40px !important;
  }

  & > button + button,
  & > a + a {
    box-shadow: none;
    border-radius: 20px;
    // margin-left: 2px; // To avoid focus shadow overlap
    line-height: 40px !important;
    height: 40px;
    padding: 0px 20px;
    color: #1d3856; // ${(props) => (props.variant === 'subtle' ? '#fff' : '#000')};
  }

  & > a {
    box-shadow: none;
    border-radius: 20px;
    // margin-left: 2px; // To avoid focus shadow overlap
    line-height: 40px !important;
    height: 40px;
    padding: 0px 20px;
    color: #1d3856; //${(props) => (props.variant === 'subtle' ? '#000' : '#fff')};
  }

  .kUyxNz {
    border-radius: 20px;
    background: linear-gradient(to right, #1d3856, #1d3856);
    box-shadow: none;
    color: #fff;
  }
  .dnrNNQ {
    border-radius: 20px;
    background: linear-gradient(to right, #1d3856, #1d3856);
    box-shadow: none;
    color: #fff;
  }

  .kkHDKd {
    border-radius: 20px;
    background: linear-gradient(to right, #1d3856, #1d3856);
    box-shadow: none;
    color: #fff;
  }
`

export default StyledButtonMenu
