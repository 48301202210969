import { ChainId } from 'config/constants'
import { ethers } from 'ethers'

export const simpleRpcProvider = new ethers.providers.StaticJsonRpcProvider('https://bsc-mainnet.public.blastapi.io')

export const DEFAULT_PROVIDERS: { [chainId in ChainId]?: ethers.providers.StaticJsonRpcProvider } = {
  [ChainId.MAINNET]: new ethers.providers.StaticJsonRpcProvider('https://bsc-mainnet.public.blastapi.io'),
  [ChainId.TESTING]: new ethers.providers.StaticJsonRpcProvider('https://data-seed-prebsc-2-s1.binance.org:8545')
}


export default null
