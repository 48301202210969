import { ChainId } from "pancake-sdk"
import { ethers } from "ethers"
import { getMulticallContract } from "./contractHelpers"
import { DEFAULT_PROVIDERS } from "./providers"


export interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (example: balanceOf)
  params?: any[] // Function params
}
export const multicallV3 = async <T = any>(chainId: ChainId, abi: any[], calls: Call[]): Promise<T> => {
  const multi = getMulticallContract(chainId, DEFAULT_PROVIDERS[chainId])
  const itf = new ethers.utils.Interface(abi)

  const calldata = calls.map((call) => ({
    target: call.address.toLowerCase(),
    callData: itf.encodeFunctionData(call.name, call.params),
  }))
  const { returnData } = await multi.aggregate(calldata)

  const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))

  return res as any
}