export default {
  Multicall: {
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    56: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  PresaleIdo: {
    97: '0xa307c705a550f8B01b5CCE3D9501c8DCF252bA7e',
    56: '0x37c999879bA765f2948Da3474d5408edE43e33B6',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  Usdt: {
    97: '0xA0402517DedA89ABE58b6Ab42Ba20B86Bf9069AF',
    56: '0x55d398326f99059fF775485246999027B3197955',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  Flt: {
    97: '0x8c62b894Ca57A95381c246E01012c9DcfA3c4Eb9',
    56: '0x4F2b0625674d18dD3994CbEe4E025aC2fFE070ae',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  ColaMint: {
    97: '0x8c62b894Ca57A95381c246E01012c9DcfA3c4Eb9',
    56: '0x9D8A3f13c1BBb9CB288F11fdA5721a920e18143c',  // '0x9D8A3f13c1BBb9CB288F11fdA5721a920e18143c',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  ColaToken: {
    97: '0x8c62b894Ca57A95381c246E01012c9DcfA3c4Eb9',
    56: '0xDFEDE13934A7E447797c500dDD604D0f82205d35',  // '0xDFEDE13934A7E447797c500dDD604D0f82205d35',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
  ColaFun: {
    97: '0x8c62b894Ca57A95381c246E01012c9DcfA3c4Eb9',
    56: '0x325A376939D93CA7b61d434F04F13DFD2f83dAA0',  // '0x325A376939D93CA7b61d434F04F13DFD2f83dAA0',
    201022: '0x46245d98C0B72FF2c64d86fCdB84ddcd18cE2E40',
  },
}
