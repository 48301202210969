import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | number | string

export enum ChainId {
  MAINNET = 56,
  TESTNET = 97,
  ARBITRUM = 42161,
  OKX = 66,
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT,
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP,
}
export const FACTORY_ADDRESS = '0x8cb25774298ff036A4849C5828b44F8D865e2B40'

export const FACTORY_ADDRESS_MAP = {
  [ChainId.MAINNET]: FACTORY_ADDRESS,
  // [ChainId.TESTNET]: '0x6725f303b657a9451d8ba641348b6761a6cc7a17'
  [ChainId.TESTNET]: '0x1217352236c9B53872Ff95A58B0dFC36A575D497',
  [ChainId.ARBITRUM]: '0xc35dadb65012ec5796536bd9864ed8773abc74c4',
  [ChainId.OKX]: '0x54FfcE3Ff7C0e89d81D6d29510a14654Aa0b009a'
}

export const INIT_CODE_HASH = '0xdafb0c9122a60d74c0b5a7c2524bb4a23b6758ffe4201d0ebdb910d56c51d269'

export const INIT_CODE_HASH_MAP = {
  [ChainId.MAINNET]: INIT_CODE_HASH,
  // [ChainId.TESTNET]: '0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66'
  [ChainId.TESTNET]: '0x9956b660c3aa77e8ab0ea294441e3b33d0cb2cdd759ec38920a0f6e04ec1212c',
  [ChainId.ARBITRUM]: '0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303',
  [ChainId.OKX]: '0xdafb0c9122a60d74c0b5a7c2524bb4a23b6758ffe4201d0ebdb910d56c51d269'
}

export interface Position {
  factory: string
  hash: string
}

export const POSITION_LIST_MAP: { [chainId in ChainId]?: Position[] } = {
  [ChainId.MAINNET]: [
    {
      factory: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
      hash: '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
    },
    {
      factory: FACTORY_ADDRESS_MAP[ChainId.MAINNET],
      hash: INIT_CODE_HASH_MAP[ChainId.MAINNET],
    },
  ],
  [ChainId.OKX]: [
    {
      factory: '0x709102921812B3276A65092Fe79eDfc76c4D4AFe',
      hash: '0xe3ae0327539fda6ee87492b9ce166b7419808c231acd1fe54dd3fbf7754704f5',
    },
    {
      factory: FACTORY_ADDRESS_MAP[ChainId.OKX],
      hash: INIT_CODE_HASH_MAP[ChainId.OKX],
    },
  ]
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const FEES_NUMERATOR = JSBI.BigInt(9970)
export const FEES_DENOMINATOR = JSBI.BigInt(10000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256',
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
}
