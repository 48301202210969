import { Avatar } from 'antd'
import React from 'react'
import { SvgProps } from '../../../components/Svg/types'
import { AvatarLogo } from '../AvatarLogo'

const Icon: React.FC<SvgProps> = (props) => (
  <AvatarLogo>
    <Avatar src="./logo/nft.png" size={25} />
  </AvatarLogo>
)

export default Icon
