import { Button, Progress } from 'antd'
import { useActiveWeb3React } from 'hooks'
import { useColaMintContract } from 'hooks/useContract'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import useI18nText from 'hooks/useI18n'
import { getColaMintContract } from 'utils/contractHelpers'
import useMint from './hooks'

const MintBody = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  background-color: #000;

  background-image: url('./imgs/mint.png') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;

  padding-top: 100px;
  box-sizing: border-box;
`

const MintContent = styled.div`
  height: 500px;
  background-color: #23a094;
  margin: 0px auto;
  padding: 45px;
  ${({ theme }) => theme.mediaQueries.xs} {
    max-width: 96%;
    padding: 20px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 900px;
    padding: 45px;
  }
  border-radius: 10px;
  text-align: center;
  .data-text {
    color: #f3c522;
    h1 {
      margin-bottom: 20px;
      ${({ theme }) => theme.mediaQueries.xs} {
        font-size: 30px;
      }
      ${({ theme }) => theme.mediaQueries.sm} {
        font-size: 40px;
      }
    }
    p {
      line-height: 22px;
    }
  }
  .schedule {
    width: 100%;
    height: 20px;
    margin: 40px 0px;
    margin-top: 70px;
    color: #fff;
    span {
      font-weight: 600;
    }
    .span1 {
      float: left;
    }
    .span2 {
      float: right;
    }
  }
`

const CoalProgress = styled(Progress)``

const CoalButton = styled(Button)`
  background-color: #f3c522;
  border: 1px solid #000;
  padding: 0px 70px;
  height: 42px;
  border-radius: 10px;
`

const Mint = () => {
  const t = useI18nText()
  const { account } = useActiveWeb3React()
  const [{ mintCounts, totalCounts }, setDataState] = useState({ mintCounts: 0, totalCounts: 0 })

  const colaMint = getColaMintContract(56)
  const { loading, $mint } = useMint()

  console.log(`loading: ${loading}`)

  const initDataState = useCallback(() => {
    if (!colaMint) return
    if (account) {
      colaMint.mintConts(account).then((_mintCounts) => {
        setDataState({ totalCounts, mintCounts: Number(_mintCounts.toString()) })
      })
    }
    console.log(colaMint)
    colaMint.totalDroped().then((_totalCounts) => {
      setDataState({ totalCounts: Number(_totalCounts.toString()), mintCounts })
    })
  }, [mintCounts, totalCounts, account, colaMint])

  /* eslint-disable */
  useEffect(() => {
    const intervalId = setInterval(initDataState, 3000)
    return () => clearInterval(intervalId)
  }, [initDataState])

  return (
    <MintBody>
      <MintContent>
        <div className="data-text">
          <h1>{t('Mint rule')}</h1>
          <p>
            {t('Price of 0.003BNB/sheet (200 $CoLa), with a maximum of 20 castings per address')} <br />
            {t('Total Casting Quantity 63000 sheets (12600000 $CoLa)')} <br />
            {t('Automatically add 42000 liquidity sheets ($8400000 CoLa)')}{' '}
          </p>
        </div>

        <div className="schedule">
          <div>
            <p className="span1">
              <span>{t('total')}：</span> 63000
            </p>
            <p className="span2">
              <span>{t('residue')}：</span> {63000 - totalCounts}
            </p>
          </div>
          <CoalProgress
            strokeColor="#ff7768"
            trailColor="#fff"
            percent={(totalCounts * 100) / 63000}
            showInfo={false}
          />
        </div>

        <div>
          <CoalButton disabled={!(mintCounts < 20)} onClick={$mint} loading={loading}>
            Mint {mintCounts}/20
          </CoalButton>
        </div>
      </MintContent>
    </MintBody>
  )
}

export default Mint
