import { Avatar } from 'antd'
import React from 'react'
import { SvgProps } from '../../../components/Svg/types'
import { AvatarLogo } from '../AvatarLogo'

const Icon: React.FC<SvgProps> = (props) => (
  <AvatarLogo style={{ color: '#000' }}>
    <Avatar src="./favicon.png" size={25} /> BobyRabbit
  </AvatarLogo>
)

export default Icon
