import { useCallback, useState } from 'react'
import { ethers, utils } from 'ethers'

import { useAppDispatch, useAppSelector } from 'state'
import { match } from 'utils/match'
import { getColaMintAddress, getPresaleIdoAddress, getUsdtAddress } from 'utils/addressHelpers'
import { useWeb3React } from '@web3-react/core'
import { message } from 'antd'

const useMint = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { library, chainId } = useWeb3React()

  const $mint = useCallback(async () => {
    try {
      setLoading(true)

      const provider = new ethers.providers.Web3Provider(window.ethereum)
      await provider.send('eth_requestAccounts', [chainId])
      const signer = await provider.getSigner()
      const tx = await signer.sendTransaction({
        to: getColaMintAddress(chainId),
        value: utils.parseEther('0.003'),
        gasLimit: '2100000',
      })
      const receipt = await tx.wait()
      if (receipt.status) {
        setLoading(false)
        message.success('Coal Mint Success')
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
      message.error(match(e.toString()) || e.toString())
    }
  }, [chainId])

  return { loading, $mint }
}

export default useMint
