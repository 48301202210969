import useI18nText from 'hooks/useI18n'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

const MintBody = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  /* background-color: #FF7051; */

  background-image: url('./imgs/pp1.png') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;

  padding-top: 100px;
  box-sizing: border-box;
  position: relative;

  text-align: center;

  p {
    ${({ theme }) => theme.mediaQueries.xs} {
      padding: 50vw 0px;
    }
    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 10vw;
    }
  }
`

const Pad = () => {
  const t = useI18nText()
  return (
    <MintBody>
      <p style={{ fontSize: '10vw', textAlign: 'center' }}>{t('Go online after New Years Day')}</p>
    </MintBody>
  )
}

export default Pad
