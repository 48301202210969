import { Avatar, Button, Input } from 'antd'
import { divide } from 'lodash'
import { ChainId, CurrencyAmount, Token, TokenAmount } from 'pancake-sdk'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import useI18nText from 'hooks/useI18n'
import { getColaFunAddress, getColaTokenAddress, getMulticallAddress } from 'utils/addressHelpers'
import { multicallV3 } from 'utils/multicall'
import ERC20_ABI from 'config/abi/Erc20.json'
import MULTICALL_ABI from 'config/abi/Multicall.json'
import { useActiveWeb3React } from 'hooks'
import { useColaFunContract, useColaMintContract } from 'hooks/useContract'
import { BigNumber, utils } from 'ethers'
import useApproveCallback, { useCreamFundHook } from './hooks'

const MintBody = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  /* background-color: #FF7051; */

  background-image: url('./imgs/pp1.png') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;

  padding-top: 100px;
  box-sizing: border-box;
  position: relative;
`

const MintContent = styled.div`
  border-radius: 10px;
  background-color: #23a094;
  position: absolute;
  ${({ theme }) => theme.mediaQueries.xs} {
    width: 96%;
    transform: translate(-50%, -65%);
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 411px;
    transform: translate(-50%, -60%);
  }
  top: 50%;
  left: 50%;

  .head {
    border-bottom: 2px solid rgb(249, 247, 248);
    padding: 24px;
    p {
      font-size: 20px;
      color: rgb(0, 0, 0);
      margin-top: 6px;
      margin-bottom: 0px;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.1;
    }
    span {
      color: rgb(0, 0, 0);
      font-weight: 400;
      line-height: 1.5;
      font-size: 14px;
    }
  }
  .body {
    padding: 24px;
    .body_1 {
      margin-bottom: 20px;
      display: flex;
      > div {
        background-color: aliceblue;
        flex: 1;
        border-radius: 16px;
        box-shadow: inset 0px 2px 2px -1px rgb(74 74 104 / 10%);
        padding: 12px 22px;
        text-align: center;
        color: #000;
        background: '#f3c522' !important;
        h1 {
          margin-bottom: 30px;
        }
        .info {
          color: #00000088;
          text-align: center;
          p {
            margin: 20px 0px;
          }
        }
      }
    }

    .botton {
      padding: 12px 22px;
      border-radius: 16px;
      box-shadow: inset 0px 2px 2px -1px rgb(74 74 104 / 10%);
      /* height: 100px; */
      background-color: aliceblue;

      > div {
        span {
          color: rgba(0, 0, 0, 0.533);
          font-weight: 400;
          line-height: 1.5;
          font-size: 14px;
        }
      }
    }
  }
`

const CoalButton = styled(Button)`
  background-color: #f3c522;
  border: 1px solid #000;
  padding: 0px 70px;
  height: 42px;
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
`

const CoalInput = styled(Input)`
  .max {
    -webkit-box-align: center;
    align-items: center;
    border: 0px;
    border-radius: 16px;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
    height: 32px;
    padding: 0px 16px;
    background-color: transparent;
    color: rgb(111, 102, 246);
    box-shadow: none;
  }

  margin-top: 20px;
  font-family: 'Kanit', sans-serif;
  .ant-input {
    border: none;
    background: none;
    position: relative;
    font-weight: 500;
    outline: none;
    border: none;
    flex: 1 1 auto;
    background-color: transparent;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px;
    appearance: textfield;
    color: rgba(0, 0, 0, 0.533) !important;
  }
  input:focus {
    border-color: none; /* 修改获取焦点时的边框颜色 */
    outline: none; /* 去掉默认的外部轮廓效果 */
  }

  .ant-input-group-addon {
    border: none;
    background: none;
    padding: 0px;
  }
`

export const fetchTokenTotalSupply = async (token: Token): Promise<TokenAmount> => {
  const calls = [
    {
      address: getColaTokenAddress(token.chainId),
      name: 'totalSupply',
      params: [],
    },
  ]
  const [totalSupply] = await multicallV3(token.chainId, ERC20_ABI, calls)
  return token && new TokenAmount(token, totalSupply[0].toString())
}

export const fetchTokenETHBalances = async (chainId: ChainId, account: string): Promise<CurrencyAmount> => {
  const calls = [
    {
      address: getMulticallAddress(chainId),
      name: 'getEthBalance',
      params: [account],
    },
    {
      address: getMulticallAddress(chainId),
      name: 'getCurrentBlockTimestamp',
      params: [],
    },
  ]
  const [balance, time] = await multicallV3(chainId, MULTICALL_ABI, calls)
  console.log(`balance: ${balance}`)
  return CurrencyAmount.ether(balance[0].toString())
}

const BurnAmount = styled.div`
  text-align: center;
  color: rgba(0, 0, 0, 0.533);
  font-size: 14px;
  margin-top: 10px;
`

const fetchCurrencyBalance = async (
  chainId: ChainId,
  toekn: Token,
  account: string,
  sender: string
): Promise<[CurrencyAmount, boolean]> => {
  const calls = [
    {
      address: toekn.address,
      name: 'balanceOf',
      params: [account],
    },
    {
      address: toekn.address,
      name: 'allowance',
      params: [account, sender],
    },
  ]
  const [balance, allowance] = await multicallV3(chainId, ERC20_ABI, calls)
  console.log(allowance)
  console.log(`balance: ${balance}`)
  return [CurrencyAmount.ether(balance[0].toString()), allowance.toString() !== '0']
}

const ColaFun = () => {
  const { chainId, account } = useActiveWeb3React()
  const t = useI18nText()
  const creamFundContract = useColaFunContract()
  const [typedValue, setTypedValue] = useState('')
  const [[fundBalance, selectedCurrencyBalance, creamTotalSupply, isApprove], setInitData] = useState<
    [CurrencyAmount, CurrencyAmount, TokenAmount, boolean]
  >([null, null, null, false])

  const cream = useMemo(
    () => new Token(chainId, getColaTokenAddress(chainId), 18, 'CREAM', 'Binance-Peg Ethereum Token'),
    [chainId]
  )

  const rewardBNB = useMemo(
    () =>
      typedValue &&
      fundBalance &&
      creamTotalSupply &&
      CurrencyAmount.ether(
        BigNumber.from(fundBalance.raw.toString())
          .mul(utils.parseEther(typedValue))
          .div(creamTotalSupply.raw.toString())
          .toString()
      ),
    [typedValue, fundBalance, creamTotalSupply]
  )

  /* eslint-disable */
  const error = useMemo(() => {
    if (BigNumber.from(selectedCurrencyBalance?.raw.toString() || '0').lt(utils.parseEther(typedValue || '0'))) {
      return `Insufficient cream balance`
    }
    if (Number(fundBalance?.toSignificant() || 0) === 0) {
      return `fund 0 bnb`
    }
    return ''
  }, [selectedCurrencyBalance, typedValue, fundBalance])

  const { loading, approve } = useApproveCallback(cream, getColaFunAddress(chainId))
  const { loading: brunLoading, creamFundHook } = useCreamFundHook(typedValue)
  useEffect(() => {
    const initData = async () => {
      if (!chainId || !cream) return

      const [_balaneOf, _isApprove] = await fetchCurrencyBalance(chainId, cream, account, creamFundContract.address)
      const _fundBalance = await fetchTokenETHBalances(chainId, getColaFunAddress(chainId))
      const _creamTotalSupply = await fetchTokenTotalSupply(cream)

      setInitData([_fundBalance, _balaneOf, _creamTotalSupply, _isApprove])
    }

    initData()
  }, [chainId, account, cream, loading, creamFundContract, brunLoading])

  return (
    <MintBody>
      <MintContent>
        <div className="head">
          <p>Cola Burn</p>
          <span>{t('Destroy Cola to obtain BNB')}</span>
        </div>
        <div className="body">
          <div className="body_1">
            <div className="creamFund">
              <h1>{t('Fund balance')}</h1>
              <div className="info">
                <Avatar size={50} src="./bnb.png" />
                <p>{fundBalance?.toSignificant() || '0'}</p>
              </div>
            </div>
            <p style={{ width: '20px' }} />
            <div className="creamToken">
              <h1>{t('Total Tokens')}</h1>
              <div className="info">
                <Avatar size={50} src="favicon.png" />
                <p>{creamTotalSupply?.toSignificant() || '0'}</p>
              </div>
            </div>
          </div>
          <div className="botton">
            <div>
              <span>Cola</span>
              <span style={{ float: 'right' }}>balance: {selectedCurrencyBalance?.toSignificant() || 0}</span>
            </div>
            <CoalInput
              value={typedValue}
              onChange={(e) => setTypedValue(e.target.value)}
              addonAfter={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p
                    className="max"
                    onClick={() => {
                      if (selectedCurrencyBalance) {
                        setTypedValue((selectedCurrencyBalance as any)?.toExact())
                      }
                    }}
                  >
                    Max
                  </p>
                  <Avatar size={26} src="favicon.png" />{' '}
                  <span style={{ fontWeight: 400, fontSize: '18px', marginLeft: '8px' }}>cola</span>
                </div>
              }
            />
          </div>
          {typedValue && (
            <BurnAmount>
              {' '}
              {typedValue === selectedCurrencyBalance?.toExact() ? Number(typedValue).toFixed(3) : typedValue} cream ={' '}
              {rewardBNB?.toSignificant() || '0'} bnb
            </BurnAmount>
          )}
          {isApprove ? (
            <CoalButton onClick={creamFundHook} loading={brunLoading}>
              {t('Burn')}
            </CoalButton>
          ) : (
            <CoalButton onClick={approve} loading={loading}>
              {t('Approve')} Cola
            </CoalButton>
          )}
        </div>
      </MintContent>
    </MintBody>
  )
}

export default ColaFun
