import styled from 'styled-components'

export const AvatarLogo = styled.div`
  margin-right: 7px;
  span {
    border-radius: 0px;
  }
`

export default {}
