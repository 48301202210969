import { useMemo } from 'react'
import {
  getColaFunContract,
  getColaMintContract,
  getERC20Contract,
  getPresaleIdoContract
} from 'utils/contractHelpers'

import {
  ColaMint,
  Erc20,
  PresaleIdo
} from 'config/abi/types'


import {
  useActiveWeb3React
} from './index'

import {
  getProviderOrSigner
} from '../utils'


export const useERC20Contract = (address: string, withSignerIfPossible = true): Erc20 => {
  const { library, account } = useActiveWeb3React()
  return useMemo(
    () => getERC20Contract(address, withSignerIfPossible ? getProviderOrSigner(library, account) : library),[
    account,
    address,
    library,
    withSignerIfPossible
  ]
  )
}

export const usePresaleIdoContract = (withSignerIfPossible = true): PresaleIdo => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(() => getPresaleIdoContract(chainId, withSignerIfPossible ? getProviderOrSigner(library, account) : library), [
    account,
    library,
    chainId,
    withSignerIfPossible,
  ])
}

export const useColaMintContract = (withSignerIfPossible = true): ColaMint => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(() => getColaMintContract(chainId, withSignerIfPossible ? getProviderOrSigner(library, account) : library), [
    account,
    library,
    chainId,
    withSignerIfPossible,
  ])
}

export const useColaFunContract = (withSignerIfPossible = true): ColaMint => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(() => getColaFunContract(chainId, withSignerIfPossible ? getProviderOrSigner(library, account) : library), [
    account,
    library,
    chainId,
    withSignerIfPossible,
  ])
}






export default {}
