import { Action, AnyAction, configureStore, getDefaultMiddleware, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'


import application from './application/reducer'
import idoReducer from './beeIdo'

import { getThemeCache } from '../utils/theme'

const PERSISTED_KEYS: string[] = ['user', 'transactions']
const loadedState = load({ states: PERSISTED_KEYS }) as any
if (loadedState.user) {
  loadedState.user.userDarkMode = getThemeCache()
}

const store = configureStore({
  reducer: {
    application,
    ido: idoReducer
  },
  middleware: [...getDefaultMiddleware({ thunk: false }), save({ states: PERSISTED_KEYS })],
  preloadedState: loadedState,
})

// store.dispatch(updateVersion())

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>

export const useAppDispatch = () => useDispatch()
export const useThunkDispatch: () => ThunkDispatch<AppState, any, AnyAction> = useDispatch
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
