import { MenuEntry } from 'pancake-uikit'

/* eslint-disable */
const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Mint',
    icon: 'HomeIcon',
    href: '/mint',
  },
  {
    label: 'Destruction of funds',
    icon: 'HomeIcon',
    href: '/colaFun',
  },
  {
    label: 'Mining',
    icon: 'HomeIcon',
    href: '/mining'
  },
  {
    label: 'Pad',
    icon: 'HomeIcon',
    href: '/pad'
  },
]

export default config
