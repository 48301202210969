import { useCallback, useState } from 'react'
import { ethers, utils } from 'ethers'

import { useAppDispatch, useAppSelector } from 'state'
import { match } from 'utils/match'
import { getColaMintAddress, getPresaleIdoAddress, getUsdtAddress } from 'utils/addressHelpers'
import { useWeb3React } from '@web3-react/core'
import { message } from 'antd'
import { Token } from 'pancake-sdk'
import { useColaFunContract, useERC20Contract } from 'hooks/useContract'

const useApproveCallback = (token: Token, sender: string) => {
  const [loading, setLoading] = useState(false)
  const { chainId } = useWeb3React()
  const erc20 = useERC20Contract(token.address)

  const approve = useCallback(async () => {
    try {
      setLoading(true)

      const tx = await erc20.approve(sender, ethers.constants.MaxUint256)
      const receipt = await tx.wait()
      if (receipt.status) {
        setLoading(false)
        message.success('approve Success')
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
      message.error(match(e.toString()) || e.toString())
    }
  }, [erc20, sender])

  return { loading, approve }
}

export default useApproveCallback

export const useCreamFundHook = (typedValue: string) => {
  const [loading, setLoading] = useState(false)
  const { chainId } = useWeb3React()

  const creamFundContract = useColaFunContract()

  const creamFundHook = useCallback(async () => {
    try {
      setLoading(true)

      const tx = await creamFundContract.burnCola(utils.parseEther(typedValue))
      const receipt = await tx.wait()
      if (receipt.status) {
        setLoading(false)
        message.success('burn Success')
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
      message.error(match(e.toString()) || e.toString())
    }
  }, [ typedValue, creamFundContract])

  return { loading, creamFundHook }
}
