import { useCallback, useContext } from 'react'
import { TranslationsContext } from 'hooks/TranslationsContext'

 const useI18nText = () => {
  const { translations } = useContext(TranslationsContext)
  /**
   * As a temporary fix memoize the translation function so it can be used in an effect.
   * It appears the TranslationsContext is always empty and is not currently used
   * TODO: Figure out if the context is used and if not, remove it.
   */
  return useCallback(
    (fallback: string) => {
      if (typeof translations === 'object') {
        if (translations[fallback] === undefined) {
          return fallback
        }
        // console.log(fallbackObject)
        const includesVariable = translations[fallback].includes('%')
        if (includesVariable) {
          // return replaceDynamicString(translations[fallback], fallbackObject)
        }
        return translations[fallback]
      }
      return fallback
    },
    [translations]
  )
}

export default useI18nText
