import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { ChainId } from 'pancake-sdk'

export const getAddress = (address: Address): string => {
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID as string, 10)
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMulticallAddress = (chainId?: ChainId) => {
  return  chainId ? addresses.Multicall[chainId] : getAddress(addresses.Multicall)
}


export const getPresaleIdoAddress = (chainId?: ChainId) => {
  return chainId ? addresses.PresaleIdo[chainId] : getAddress(addresses.PresaleIdo)
}

export const getUsdtAddress = (chainId?: ChainId) => {
  return chainId ? addresses.Usdt[chainId] : getAddress(addresses.Usdt)
}

export const getFltAddress = (chainId?: ChainId) => {
  return chainId ? addresses.Flt[chainId] : getAddress(addresses.Flt)
}

export const getColaMintAddress = (chainId?: ChainId) => {
  return chainId ? addresses.ColaMint[chainId] : getAddress(addresses.ColaMint)
}

export const getColaFunAddress = (chainId?: ChainId) => {
  return chainId ? addresses.ColaFun[chainId] : getAddress(addresses.ColaFun)
}
export const getColaTokenAddress = (chainId?: ChainId) => {
  return chainId ? addresses.ColaToken[chainId] : getAddress(addresses.ColaToken)
}