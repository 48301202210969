import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ethers, utils } from 'ethers'
import { getPresaleIdoAddress, getFltAddress, getUsdtAddress } from 'utils/addressHelpers'
import Erc20_abi from 'config/abi/Erc20.json'
import PresaleIdo_abi from 'config/abi/PresaleIdo.json'
import { Call, multicall } from 'utils/calls/multicall'
import { ChainId } from 'config/constants'
import { NETWORK_CHAIN_ID } from 'connectors'

export const fetchBeeIDOData = async (
  chainId: ChainId = NETWORK_CHAIN_ID,
  acconut: string = ethers.constants.AddressZero
): Promise<IDOState> => {
  const calls: Call[] = [
    // {
    //   address: getPresaleIdoAddress(chainId),
    //   name: 'idoList',
    //   params: [1],
    // },
    // {
    //   address: getUsdtAddress(chainId),
    //   name: 'decimals',
    //   params: [],
    // },
    // {
    //   address: getFltAddress(chainId),
    //   name: 'decimals',
    //   params: [],
    // },
    // {
    //   address: getFltAddress(chainId),
    //   name: 'balanceOf',
    //   params: [getPresaleIdoAddress(chainId)],
    // },
    // {
    //   address: getPresaleIdoAddress(chainId),
    //   name: 'userList',
    //   params: [acconut, 1],
    // },
    // {
    //   address: getPresaleIdoAddress(chainId),
    //   name: 'getPending',
    //   params: [acconut, 1],
    // },
    // {
    //   address: getUsdtAddress(chainId),
    //   name: 'allowance',
    //   params: [acconut, getPresaleIdoAddress(chainId)],
    // },
    // {
    //   address: getUsdtAddress(chainId),
    //   name: 'balanceOf',
    //   params: [acconut],
    // },
    // {
    //   address: getPresaleIdoAddress(chainId),
    //   name: 'owner',
    //   params: [],
    // },
  ]

  console.log(calls)

  // 创建合约对象
  const result = await multicall(chainId, PresaleIdo_abi.concat(Erc20_abi), calls)
  const [ido2, decimals, lftDecimals, idoBalance, user2, beePendingList, usdtApprove, usdtBalance, owner] = result

  console.log(Number(utils.formatUnits(idoBalance[0], lftDecimals[0])))

  console.log(result)
  return {
    balance: acconut ? Number(utils.formatUnits(usdtBalance[0].toString(), decimals[0])).toFixed(3) : '0',
    approve: acconut ? usdtApprove[0].gt('0') : false,
    usdtDecimals: decimals ? decimals[0] : 18,
    idoBalance: Number(utils.formatUnits(idoBalance[0], lftDecimals[0])),
    owner: owner.toString(),
    ido2: {
      minCore: Number(utils.formatUnits(ido2[0], decimals[0])),
      maxCore: Number(utils.formatUnits(ido2[1], decimals[0])),
      totleCollectCore: Number(utils.formatUnits(ido2[2], decimals[0])),
      collectCore: Number(Number(utils.formatUnits(ido2[3], decimals[0])).toFixed(3)),
      coreOrBee: Number(Number(utils.formatEther(ido2[4].toString())).toFixed(2)),
      totlePartake: Number(ido2[5].toString()),
      endTime: Number(ido2[6].toString()) * 1000,
      isEnd: ido2[7] ? ido2[7] : Number(ido2[6].toString()) < new Date().valueOf() / 1000,
      fallbackAmount: acconut ? Number(Number(utils.formatUnits(beePendingList[0], decimals[0])).toFixed(5)) : 0,
      tokenPending: acconut ? Number(utils.formatUnits(beePendingList[1], lftDecimals[0])).toFixed(3) : '0',
      totalSupply: 2000000000,
      user: acconut
        ? {
            amount: Number(utils.formatUnits(user2[0], decimals[0])).toFixed(3),
            isDraw: user2[1],
            isBuy: user2[2],
            fallbackAmount: Number(utils.formatUnits(user2[3], decimals[0])).toFixed(3),
            receivedAmount: Number(utils.formatUnits(user2[4], lftDecimals[0])).toFixed(3),
          }
        : undefined,
    },
    // isWhite: acconut ? isWhite[0] : false,
  }
}

interface BeeIdoUserState {
  amount: string
  isDraw: boolean
  isBuy: boolean
  fallbackAmount: string
  receivedAmount: string
}

interface BeeIdoState {
  minCore: number
  maxCore: number
  totleCollectCore: number
  collectCore: number
  coreOrBee: number
  totlePartake: number
  endTime: number
  isEnd: boolean
  totalSupply?: number
  tokenPending?: string
  fallbackAmount?: number

  user: BeeIdoUserState
}

interface IDOState {
  ido1?: BeeIdoState
  ido2?: BeeIdoState
  balance?: string
  approve?: boolean
  isWhite?: boolean
  usdtDecimals?: number
  idoBalance?: number
  owner?: string
}

const initialState: IDOState = {}

export const IdoSlice = createSlice({
  name: 'beeIdo',
  initialState,
  reducers: {
    setBeeIdoUserData: (state, action) => {
      // console.log(action)
      state.usdtDecimals = action.payload.usdtDecimals
      state.ido2 = action.payload.ido2
      state.balance = action.payload.balance
      state.approve = action.payload.approve
      state.idoBalance = action.payload.idoBalance
      state.owner = action.payload.owner
    },
  },
})

// Actions
export const { setBeeIdoUserData } = IdoSlice.actions

export default IdoSlice.reducer
